import React, { useState, useEffect, useRef } from "react";

const ResizableTwoDivs = ({ minWidth = 200, leftSide, rightSide }) => {
    const [leftWidth, setLeftWidth] = useState(0);
    const [containerWidth, setContainerWidth] = useState(0);
    const [isResizing, setIsResizing] = useState(false);
    const containerRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            setContainerWidth(container.offsetWidth);
            setLeftWidth(container.offsetWidth / 2);
        }

        const updateContainerWidth = () => {
            if (container) {
                setContainerWidth(container.offsetWidth);
            }
        };
        window.addEventListener("resize", updateContainerWidth);
        return () => {
            window.removeEventListener("resize", updateContainerWidth);
        };
    }, []);

    useEffect(() => {
        const handleMouseMove = (e) => {
            if (isResizing && containerRef.current) {
                const containerRect = containerRef.current.getBoundingClientRect();
                const newLeftWidth = e.clientX - containerRect.left;
                if (
                    newLeftWidth >= minWidth &&
                    newLeftWidth <= containerWidth - minWidth
                ) {
                    setLeftWidth(newLeftWidth);
                }
            }
        };

        const handleMouseUp = () => {
            if (isResizing) {
                setIsResizing(false);
            }
        };

        if (isResizing) {
            window.addEventListener("mousemove", handleMouseMove);
            window.addEventListener("mouseup", handleMouseUp);
        }

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
        };
    }, [isResizing, minWidth, containerWidth]);

    return (
        <div
            ref={containerRef}
            className="flex flex-col md:flex-row h-full w-full"
        >
            <div
                className="bg-gray-100 md:flex-shrink-0 md:w-auto w-full border-none border-gray-300"
                style={{ width: containerWidth >= 768 ? leftWidth : "100%" }}
            >
                {leftSide}
            </div>

            {containerWidth >= 768 && (
                <div
                    onMouseDown={() => setIsResizing(true)}
                    className="w-3 bg-gray-400 cursor-col-resize flex flex-row items-center justify-center gap-[3px]"
                >
                    <span className="w-[1px] h-8 bg-black "></span>
                    <span className="w-[1px] h-8 bg-black "></span>
                </div>
            )}

            <div
                className="bg-white flex-grow w-full"
                style={{
                    width:
                        containerWidth >= 768
                            ? containerWidth - leftWidth - 100
                            : "100%",
                }}
            >
                {rightSide}
            </div>
        </div>
    );
};

export default ResizableTwoDivs;
