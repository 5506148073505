export const getLocationRadiusCoordinates = (lat, lon, radius) => {
  const milesPerDegreeLat = 69;
  const milesPerDegreeLon = 69 * Math.cos(lat * (Math.PI / 180));

  const deltaLat = radius / milesPerDegreeLat;
  const deltaLon = radius / milesPerDegreeLon;

  return {
    minLat: lat - deltaLat,
    maxLat: lat + deltaLat,
    minLon: lon - deltaLon,
    maxLon: lon + deltaLon,
  };
};
export const storeLocalData = (name, data) => localStorage.setItem(name, data);
export const getLocalData = (name) => localStorage.getItem(name);
export const removeLocalData = (name) => localStorage.removeItem(name);

export const storeObjectData = (name, data) =>
  localStorage.setItem(name, JSON.stringify(data));
export const getObjectData = (name) => JSON.parse(localStorage.getItem(name));

export const getCurrentLocation = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export const clearStorage = () => {
  localStorage.clear();
  sessionStorage.clear();
  if ("caches" in window) {
    caches.keys().then((cacheNames) => {
      cacheNames.forEach((cacheName) => caches.delete(cacheName));
    });
  }
  window.location.href = "/";
};

const currentTime = new Date();

export const formattedCurrent = currentTime.toLocaleString("sv-SE").replace(" ", "T");
