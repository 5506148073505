export const HUBSPOT_PORTAL_ID =
  process.env.REACT_APP_HUBSPOT_PORTAL_ID || "24471326";

export const MEETING_OUTCOME = [
  {
    label: "Scheduled",
    value: "SCHEDULED",
  },
  {
    label: "Completed",
    value: "COMPLETED",
  },
  {
    label: "Rescheduled",
    value: "RESCHEDULED",
  },
  {
    label: "No Show",
    value: "NO_SHOW",
  },
  {
    label: "Canceled",
    value: "CANCELED",
  },
  {
    label: "Canvassed",
    value: "CANVASSED",
  },
];

export const HOUSE_DISPOSITION_OPTIONS = [
  {
    label: "Household Completed",
    value: "Household Completed",
  },
  {
    label: "Household Partially Canvassed",
    value: "Household Partially Canvassed",
  },
  {
    label: "No Answer",
    value: "No Answer",
  },
  {
    label: "Not Attempted",
    value: "Not Attempted",
  },
];

export const ANALYTICS_SOURCE = [
  {
    label: "Organic Search",
    value: "ORGANIC_SEARCH",
  },
  {
    label: "Paid Search",
    value: "PAID_SEARCH",
  },
  {
    label: "Email Marketing",
    value: "EMAIL_MARKETING",
  },
  {
    label: "Organic Social",
    value: "SOCIAL_MEDIA",
  },
  {
    label: "Referrals",
    value: "REFERRALS",
  },
  {
    label: "Other Campaigns",
    value: "OTHER_CAMPAIGNS",
  },
  {
    label: "Direct Traffic",
    value: "DIRECT_TRAFFIC",
  },
  {
    label: "Offline Sources",
    value: "OFFLINE",
  },
  {
    label: "Paid Social",
    value: "PAID_SOCIAL",
  },
];
