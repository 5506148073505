import React, { useContext, useEffect, useRef, useState } from "react";
import { Dialog } from "@headlessui/react";
import { IoMdStar } from "react-icons/io";
import axios from "axios";
import toast from "react-hot-toast";
import TrackingMap from "./TrackingMap";
import { FiFilter } from "react-icons/fi";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import EditInputField from "./EditInputField";
import SearchInputField from "./SearchInputField";
import {
  createDatabase,
  storeDataInIndexedDB,
  fetchDataFromIndexedDB,
  updateDataInIndexedDB,
} from "../Hooks/IndexedDBUtils";
import AssociatedContact from "./AssociatedContact";
import { IoIosContacts } from "react-icons/io";
import { SyncLoader } from "react-spinners";
import { HOUSE_DISPOSITION_OPTIONS } from "../constants";
import ButtonLoader from "./ButtonLoader";
import AsyncSelect from "react-select/async";
import { getLocationRadiusCoordinates } from "../utils";
import { LocationContext } from "./LocationContext";
import { BsHouseAdd } from "react-icons/bs";
import GoogleMapAutocomplete from "./Autocomplete";
import ResizablePanel from "../utils/resizablePanel";

const { REACT_APP_BASE_URL } = process.env;

export const syncableTracking = createDatabase(
  "syncableTrackingDB",
  "syncableTrackingStore"
);
export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "auto",
    padding: "6px",
    // height: "700px",
    // padding: "50px 30px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "transparent",
    border: "none",
    // overflow: "auto"
  },
  overlay: {
    backgroundColor: "rgba(17, 24, 39, 0.5)",
    zIndex: "9999",
  },
};

const CanvassMap = () => {
  const mapData = useRef([]);
  const [dataList, setDataList] = useState([]);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const [hideLoading, setHideLoading] = useState(false);
  const [searchCity, setSearchCity] = useState("");
  const [searchCensus_block, setSearchCensus_block] = useState("");
  const [searchPresence_of_children_code, setSearchpresence_of_children_code] =
    useState();
  const [searchStreetName, setSearchStreetName] = useState("");
  const [page, setPage] = useState(1);
  const limit = 100;
  const [userId, setUserId] = useState("");
  const [mailing_city, setMailing_city] = useState("");
  const [coordinate_latitude, setCoordinate_latitude] = useState("");
  const [coordinate_longitude, setCoordinate_longitude] = useState("");
  const [mailing_address_line, setMailing_address_line] = useState("");
  const [presence_of_children_code, setPresence_of_children_code] =
    useState("");
  const [
    residence_household_gender_composition,
    setResidence_household_gender_composition,
  ] = useState("");
  const [houseHoldDisposition, setHouseHoldDisposition] = useState("");
  const [mailing_adress_zip, setMailing_adress_zip] = useState("");
  const [canvasser_email, setCanvasser_email] = useState("");
  const [residence_state, setResidence_state] = useState("");
  const [residence_zip, setResidence_zip] = useState("");
  // const [tracking_attempts, setTracking_attempts] = useState("");
  // const [tracking_rating, setTracking_rating] = useState("");
  // const [tracking_conversion, setTracking_conversion] = useState("");
  const token = localStorage.getItem("token");
  const userMail = localStorage.getItem("urserMail");
  const [totalAttempt, setTotalAttempts] = useState("");
  const [totalConversion, setTotalConversion] = useState("");
  const [totalRating, setTotalRating] = useState("");
  const [associatedContact, setAssociatedContact] = useState([]);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [showAssociateList, setShowAssociateList] = useState(false);
  const [l2Id, setL2Id] = useState(null);
  const [isAssociateListLoading, setIsAssociateListLoading] = useState(false);
  const [latLngMap, setLatLngMap] = useState({});
  const [isOpenFromMap, setIsOpenFromMap] = useState(false);
  const userRole = localStorage.getItem("userRoles");
  const cityname = localStorage.getItem("cityname");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [filterPayload, setFilterPayload] = useState(null);
  const [contactedVoters, setContactedVoters] = useState(0);
  const ownerID = localStorage.getItem("ownerId");
  const [allCityOptions, setAllCityOptions] = useState([]);
  const [listMemberData, setListMemberData] = useState([]);
  const [selectedMemberList, setSelectedMemberList] = useState("");
  const [selectedZipCode, setSelectedZipCode] = useState("");
  const { locationPermission, setLocationPermission, location, setLocation } =
    useContext(LocationContext);
  const [showAddHouseHold, setShowAddHouseHold] = useState(false);
  const [houseHoldUserId, setHouseHoldUserId] = useState("");
  const [responseData, setResponseData] = useState({});
  const [showContact, setShowContact] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [formData, setFormData] = useState({
    presence_of_children_code: "",
    residence_household_gender_composition: "",
    household_disposition: "" || null,
  });
  const [isLoadingCoordinate, setIsLoadingCoordinates] = useState(false);
  const [selected, setSelected] = useState('')
  const Coordinates = getLocationRadiusCoordinates(
    location?.latitude,
    location?.longitude,
    0.25
  );
  const sortData = window.sortData;
  const [addHHResponse, setAddHHResponse] = useState({
    mailing_city: "",
    residence_state: "",
    residence_zip: ""

  })
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const trackingdatabase = createDatabase("TrackingDB", "trackingStore");

  const getListMemberShipList = async () => {
    try {
      const res = await axios.get(
        `${REACT_APP_BASE_URL}/list_membership_household`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setListMemberData(res?.data?.options);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (listMemberData?.length === 0) {
      getListMemberShipList();
    }

    return () => {
      window.canvassingFilters = ''
      window.sortData = ""
    }

  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      const updatedPermission =
        JSON.parse(localStorage.getItem("isAllowLocation")) || false;
      const updatedLocation =
        JSON.parse(localStorage.getItem("currentLocation")) || {};

      setLocationPermission(updatedPermission);
      setLocation(updatedLocation);
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const fatchStats = async () => {
    await axios
      .post(
        `${REACT_APP_BASE_URL}/contactStatistics `,
        {
          email: userMail,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setTotalAttempts(res?.data?.tracking_attempts || 0);
        setTotalConversion(res?.data?.tracking_conversion_percent || 0);
        setTotalRating(res?.data?.tracking_rating || 0);
        setContactedVoters(res?.data?.tracking_contacted_voters_percent || 0);
      })
      .catch((e) => {
        toast.error(e?.message || "Sonething went wrong!");
      });
  };

  const handleEditClick = (user, isOpenFromMap) => {
    openModal();
    setUserId(user?.id);
    setL2Id(user?.properties?.l2_household_id);
    setCanvasser_email(user?.properties?.canvasser_email || "");
    setCoordinate_latitude(user?.properties?.coordinate_latitude || "");
    setCoordinate_longitude(user?.properties?.coordinate_longitude || "");
    setMailing_address_line(user?.properties?.mailing_address_line || "");
    setMailing_adress_zip(user?.properties?.mailing_adress_zip || "");
    setMailing_city(user?.properties?.mailing_city || "");
    setPresence_of_children_code(
      user?.properties?.presence_of_children_code || ""
    );
    setResidence_household_gender_composition(
      user?.properties?.residence_household_gender_composition || ""
    );
    setResidence_state(user?.properties?.residence_state || "");
    setResidence_zip(user?.properties?.residence_zip || "");
    setHouseHoldDisposition(user?.properties?.household_disposition || "");
    setIsOpenFromMap(isOpenFromMap);
  };

  const getCityOptions = async () => {
    try {
      const response = await axios.get(`${REACT_APP_BASE_URL}/getCity`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data) {
        const options = response.data.cities.map((city) => ({
          value: city.name,
          label: city.name,
        }));
        setAllCityOptions(options);
      }
    } catch (error) {
      console.error("Error fetching city data:", error);
    }
  };
  const surveyEntryData = async (
    customPage = null,
    key,
    isRenderMap = true
  ) => {
    setLoading(true);
    const pageNumber = customPage ? customPage : page;
    try {
      let data = {};
      if (filterPayload && key !== "RESET") {
        if (userRole === "ROLE_REGIONAL_MANAGER") {
          data = {
            search_city: cityname,
            offset: (pageNumber - 1) * limit,
            limit: limit,
            ...filterPayload,
          };
        } else {
          data = {
            email: userMail,
            offset: (pageNumber - 1) * limit,
            limit: limit,
            ...filterPayload,
          };
        }
      } else {
        if (userRole === "ROLE_REGIONAL_MANAGER") {
          data = {
            search_city: cityname,
            offset: (pageNumber - 1) * limit,
            limit: limit,
          };
        } else {
          data = {
            email: userMail,
            offset: (pageNumber - 1) * limit,
            limit: limit,
          };
        }
      }

      if (sortData !== null && !filterPayload) {
        data = {
          email: userMail,
          offset: (pageNumber - 1) * limit,
          limit: limit,
          sort_by: sortData?.sortBy,
          direction: sortData?.direction.toUpperCase(),
        };
      }

      if (key === "RESET") {
        window.canvassingFilters = '';
        window.sortData = '';
      }

      await axios
        .post(
          `${REACT_APP_BASE_URL}/householdList`,
          {
            ...data,
            owner_id: ownerID,
            ...(userRole === "ROLE_REGIONAL_MANAGER" ? { role: userRole } : ""),
            ...(locationPermission && Object.keys(location).length > 0
              ? {
                current_lat_long: [location?.latitude, location?.longitude],
                min_lat: Coordinates?.minLat,
                min_long: Coordinates?.minLon,
                max_lat: Coordinates?.maxLat,
                max_long: Coordinates?.maxLon,
              }
              : {}),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          const newData = res.data.results;
          setTotalRecord(res?.data?.total);
          if (newData.length === 0) {
            setAllDataLoaded(true);
          } else {
            setAllDataLoaded(false);
            setIsCheckedAll(true);
            const newArray = newData.map((data) => ({
              ...data,
              is_check: true,
            }));
            setDataList((prevData) =>
              customPage ? newArray : [...prevData, ...newArray]
            );
            // if (isRenderMap) {
            mapData.current = customPage
              ? newArray
              : [...mapData.current, ...newArray];
            // }

            setPage(customPage ? customPage + 1 : page + 1);
            if (newData.length > 0) {
              storeDataInIndexedDB(trackingdatabase, "trackingStore", newData);
            }
          }

          setLoading(false);
          setHideLoading(false);
        })
        .catch((error) => {
          if (error.response) {
            toast.error(error?.response?.data?.message);
          }
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDataFromIndexedDB(trackingdatabase, "trackingStore").then((data) => {
      if (navigator.onLine === false) {
        setDataList(data);
        mapData.current = data;
      }
    });
  }, []);

  useEffect(() => {
    if (navigator.onLine) {
      surveyEntryData(1);
      getCityOptions();
    }
  }, []);

  const loadCityOptions = async (inputValue) => {
    try {
      const response = await axios.get(`${REACT_APP_BASE_URL}/getCity`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data) {
        const filteredOptions = response.data.cities
          .filter((city) =>
            city.name.toLowerCase().includes(inputValue.toLowerCase())
          )
          .map((city) => ({
            value: city.name,
            label: city.name,
          }));
        return filteredOptions;
      }
    } catch (error) {
      console.error("Error fetching city data:", error);
      return [];
    }
  };

  const handlePagination = () => {
    if (!loading && !allDataLoaded) {
      surveyEntryData();
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitLoading(true);
    try {
      const data = {
        id: userId,
        canvasser_email: canvasser_email || "",
        coordinate_latitude: coordinate_latitude || "",
        coordinate_longitude: coordinate_longitude || "",
        mailing_address_line: mailing_address_line || "",
        mailing_adress_zip: mailing_adress_zip || "",
        mailing_city: mailing_city || "",
        presence_of_children_code: presence_of_children_code || "",
        residence_household_gender_composition:
          residence_household_gender_composition || "",
        residence_state: residence_state || "",
        residence_zip: residence_zip || "",
        household_disposition: houseHoldDisposition || null,
      };
      const storedSyncableTracking = await fetchDataFromIndexedDB(
        syncableTracking,
        "syncableTrackingStore"
      );

      if (navigator.onLine === false) {
        const updatedIndexData = {
          id: data?.id,
          properties: {
            ...data,
          },
        };
        delete updatedIndexData.properties.id;
        if (storedSyncableTracking) {
          storeDataInIndexedDB(syncableTracking, "syncableTrackingStore", [
            ...storedSyncableTracking,
            updatedIndexData,
          ]);
          closeModal();
          toast.success("Household offline updated successfully!");
        } else {
          storeDataInIndexedDB(syncableTracking, "syncableTrackingStore", [
            updatedIndexData,
          ]);
          closeModal();
          toast.success("Household offline updated successfully!");
        }
        updateDataInIndexedDB(
          trackingdatabase,
          "trackingStore",
          data?.id,
          updatedIndexData
        );
      } else {
        const res = await axios.put(
          `${REACT_APP_BASE_URL}/householdList`,
          { ...data, owner_id: ownerID },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        surveyEntryData(1, null, false);
        if (res.data) {
          toast.success("Household updated successfully!");
          closeModal();
        }
        setTimeout(() => {
          fatchStats();
        }, 1000);
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setIsSubmitLoading(false);
    }
  };
  const filterOnline = async () => {
    setLoading(true);
    setHideLoading(true);
    try {
      const data = {};
      if (searchCity?.trim()) {
        data.search_city = searchCity;
      }
      if (searchCensus_block?.trim()) {
        data.census_block = searchCensus_block;
      }
      if (searchPresence_of_children_code?.trim()) {
        data.presence_of_children_code = searchPresence_of_children_code;
      }
      if (searchStreetName?.trim()) {
        data.residence_address_line = searchStreetName;
      }
      setFilterPayload(data);

      const res = await axios.post(
        `${REACT_APP_BASE_URL}/householdList`,
        {
          email: userMail,
          owner_id: ownerID,
          offset: 0,
          limit: limit,
          ...(selectedZipCode ? { residence_zip: selectedZipCode } : ""),
          ...(userRole === "ROLE_REGIONAL_MANAGER"
            ? {
              role: userRole,
              ...(!data.searchCity ? { search_city: cityname } : {}),
            }
            : {}),
          ...(selectedMemberList
            ? { list_membership: selectedMemberList }
            : ""),
          ...(locationPermission && Object.keys(location).length > 0
            ? {
              current_lat_long: [location?.latitude, location?.longitude],
              min_lat: Coordinates?.minLat,
              min_long: Coordinates?.minLon,
              max_lat: Coordinates?.maxLat,
              max_long: Coordinates?.maxLon,
            }
            : {}),
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // localStorage.setItem("canavassingAppFilters", JSON.stringify(data));
      window.canvassingFilters = { ...data }
      const newArray = res?.data?.results.map((data) => ({
        ...data,
        is_check: true,
      }));
      setDataList(newArray);
      mapData.current = newArray;
      setLoading(false);
    } catch (error) {
      console.error("API error:", error);
      setLoading(false);
    }
    setLoading(false)
  };
  const filterOffline = () => {
    let filtered = dataList.slice();
    if (searchCity) {
      filtered = filtered.filter(
        (user) =>
          user.properties.mailing_city &&
          user.properties.mailing_city
            .toLowerCase()
            .includes(searchCity.toLowerCase())
      );
    }
    if (searchCensus_block) {
      filtered = filtered.filter(
        (user) =>
          user.properties.census_block &&
          user.properties.census_block
            .toLowerCase()
            .includes(searchCensus_block.toLowerCase())
      );
    }
    if (searchPresence_of_children_code) {
      filtered = filtered.filter(
        (user) =>
          user.properties.presence_of_children_code &&
          user.properties.presence_of_children_code
            .toLowerCase()
            .includes(searchPresence_of_children_code.toLowerCase())
      );
    }
    if (searchStreetName) {
      filtered = filtered.filter(
        (user) =>
          user.properties.mailing_address_line &&
          user.properties.mailing_address_line
            .toLowerCase()
            .includes(searchStreetName.toLowerCase())
      );
    }
    if (selectedMemberList) {
      filtered = filtered.filter(
        (user) =>
          user.properties.list_membership &&
          user.properties.list_membership === selectedMemberList
      );
    }
    if (selectedZipCode) {
      filtered = filtered.filter(
        (user) =>
          user.properties.residence_zip &&
          user.properties.residence_zip === selectedZipCode
      );
    }
    setDataList(filtered);
    mapData.current = filtered;
  };
  const applyFilters = (e) => {
    e.preventDefault();
    if (navigator.onLine) {
      filterOnline();
    } else {
      filterOffline();
    }
  };

  const handleCheckedItem = (index, data) => {
    dataList[index].is_check = !data.is_check;
    const unChecked = dataList.find((data) => !data.is_check);
    setIsCheckedAll(unChecked ? false : true);
    setDataList([...dataList]);
    mapData.current = [...dataList];
  };

  const handleCheckedAll = () => {
    setIsCheckedAll(!isCheckedAll);
    const newArray = dataList.map((data) => ({
      ...data,
      is_check: !isCheckedAll,
    }));
    setDataList([...newArray]);
    mapData.current = [...newArray];
  };

  useEffect(() => {
    if (navigator.onLine) {
      DatabaseProperty();
      setTimeout(() => {
        fatchStats();
      }, 1000);
    }
  }, []);
  const showAssociateContact = async (id) => {
    if (id) {
      setIsAssociateListLoading(true);
      setShowAssociateList((prevState) => !prevState);
      try {
        const response = await axios.post(
          `${REACT_APP_BASE_URL}/householdDatabase`,
          {
            householdID: id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setAssociatedContact({ [id]: response.data.results });
        setIsAssociateListLoading(false);
      } catch (error) {
        console.error("Error fetching associate contact:", error);
      } finally {
        setIsAssociateListLoading(false);
      }
    }
  };

  const sortTracking = async (sortBy, direction, customPage = 0) => {
    setLoading(true);
    let sortData = {
      sortBy: sortBy,
      direction: direction,
    };

    // localStorage.setItem("sortData", JSON.stringify(sortData));
    window.sortData = { ...sortData }
    const pageNumber = customPage ? customPage : page;
    if (navigator.onLine) {
      try {
        let data = window.canvassingFilters
          ? {
            ...window.canvassingFilters,
            email: userMail,
            offset: 0,
            limit: limit,
            sort_by: sortBy,
            direction: direction.toUpperCase(),
          }
          : {
            email: userMail,
            offset: 0,
            limit: limit,
            sort_by: sortBy,
            direction: direction.toUpperCase(),
          };
        const res = await axios.post(
          `${REACT_APP_BASE_URL}/householdList`,
          {
            ...data,
            owner_id: ownerID,
            ...(locationPermission && Object.keys(location).length > 0
              ? {
                current_lat_long: [location?.latitude, location?.longitude],
                min_lat: Coordinates?.minLat,
                min_long: Coordinates?.minLon,
                max_lat: Coordinates?.maxLat,
                max_long: Coordinates?.maxLon,
              }
              : {}),
            ...(userRole === "ROLE_REGIONAL_MANAGER"
              ? {
                role: userRole,
                ...(!data.searchCity ? { search_city: cityname } : {}),
              }
              : {}),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const newData = res.data.results;
        if (newData.length === 0) {
          setAllDataLoaded(true);
        } else {
          setAllDataLoaded(false);
          setIsCheckedAll(true);
          const newArray = newData.map((data) => ({
            ...data,
            is_check: true,
          }));
          setDataList((prevData) =>
            customPage ? newArray : [...prevData, ...newArray]
          );
          mapData.current = customPage
            ? newArray
            : [...mapData.current, ...newArray];
          setPage(customPage ? 1 : page + 1);
        }
      } catch (error) {
        if (error.response) {
          toast.error(error?.response?.data?.message);
        }
      } finally {
        setLoading(false);
        setHideLoading(false);
      }
    } else {
      const sortedData = [...dataList].sort((a, b) => {
        if (direction === "ascending") {
          return a.properties[sortBy] > b.properties[sortBy] ? 1 : -1;
        } else {
          return a.properties[sortBy] < b.properties[sortBy] ? 1 : -1;
        }
      });
      setDataList(sortedData);
      mapData.current = sortedData;
      setLoading(false);
      setHideLoading(false);
    }
  };
  const sortDatabaseCityDescending = async (customPage = null) => {
    await sortTracking("residence_city", "descending", customPage);
  };
  const sortDatabaseCityAscending = async (customPage = null) => {
    await sortTracking("residence_city", "ascending", customPage);
  };
  const sortDatabaseAddressDescending = async (customPage = null) => {
    await sortTracking("residence_address_line", "descending", customPage);
  };
  const sortDatabaseAddressAscending = async (customPage = null) => {
    await sortTracking("residence_address_line", "ascending", customPage);
  };

  const [isCityAscending, setIsCityAscending] = useState(false);
  const [isAddressAscending, setIsAddressAscending] = useState(false);

  const toggleCitySortingOrder = () => {
    setIsCityAscending((prevState) => !prevState);
    setIsAddressAscending();
  };
  const toggleAddressSortingOrder = () => {
    setIsAddressAscending((prevState) => !prevState);
    setIsCityAscending();
  };
  const [dataProperty, setDataproperty] = useState([]);

  const DatabaseProperty = async () => {
    let data = "";
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_URL}/databaseProperties`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data) {
        setDataproperty(response.data);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  const mapRef = useRef();

  const handleRefresh = () => {
    const map = mapRef.current;
    if (map) {
      map.panTo(defaultCenter);
      map.setZoom(9);
    }
  };

  const handleReset = () => {
    if (navigator.onLine === true) {
      setFilterPayload(null);
      // localStorage.removeItem("sortData");
      window.canvassingFilters = ''
      window.sortData = ""
      // localStorage.removeItem("canavassingAppFilters")
      setIsCityAscending();
      setIsAddressAscending();
      surveyEntryData(1, "RESET");
      handleRefresh();
    } else {
      fetchDataFromIndexedDB(trackingdatabase, "trackingStore").then((data) => {
        setDataList(data);
        mapData.current = data;
      });
    }
  };
  const handleInputChange = async (name, value) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (
      formData?.residence_address_line !== "" &&
      formData.mailing_city !== "" &&
      formData?.residence_state !== "" &&
      formData?.residence_zip !== "" &&
      formData?.coordinate_latitude === "" &&
      formData?.coordinate_longitude === ""
    ) {
      setIsLoadingCoordinates(true);
      const address = `${formData?.residence_address_line},${formData.mailing_city}, CT, USA`;
      const res = await getLatLongFromAddress(address);
      setFormData((prev) => ({
        ...prev,
        coordinate_latitude: res.lat.toFixed(6),
        coordinate_longitude: res.lng.toFixed(6),
      }));
      setIsLoadingCoordinates(false);
    }
  };
  useEffect(() => {
    handleReset();
  }, [locationPermission, location]);

  const defaultPosition = dataList.length > 0 ? dataList[0] : null;
  var defaultCenter = Object.values(latLngMap)[0];
  if (locationPermission) {
    defaultCenter = {
      lat: location?.latitude,
      lng: location?.longitude,
    };
  } else if (defaultPosition) {
    const lat = parseFloat(defaultPosition?.properties?.latitude_numeric);
    const lng = parseFloat(defaultPosition?.properties?.longitude_numeric);
    if (!isNaN(lat) && !isNaN(lng)) {
      defaultCenter = defaultCenter;
    }
  }

  const handleOnLoad = (map) => {
    mapRef.current = map;
    const bounds = new window.google.maps.LatLngBounds();
    dataList.forEach((data) => {
      const position = {
        lat: parseFloat(data?.properties?.latitude_numeric),
        lng: parseFloat(data?.properties?.longitude_numeric),
      };
      bounds.extend(position);
    });
    map.fitBounds(bounds);
  };

  useEffect(() => {
    const fetchData = async () => {
      const latLngMap = {};
      const promises = dataList
        .filter((data) => data.is_check)
        .map(async (data) => {
          const address = `${data.properties.residence_address_line}, ${data.properties.mailing_city}, CT, USA`;
          const latLng = locationPermission
            ? {
              lat: data.properties.latitude_numeric,
              lng: data.properties.longitude_numeric,
            }
            : await getLatLongFromAddress(address);

          latLngMap[data.id] = latLng;
        });
      await Promise.all(promises);
      setLatLngMap(latLngMap);
      setLoading(false);
    };
    fetchData();
  }, [dataList]);

  async function getLatLongFromAddress(address) {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=AIzaSyBwBtxw1WbNeANR4SlUgLHN3nuhF1qPaWs`
      );
      const location = response?.data?.results[0]?.geometry?.location;
      const latLng = { lat: location?.lat, lng: location?.lng };
      return latLng;
    } catch (error) {
      console.error("Error fetching geolocation data:", error);
      return null;
    }
  }
  const mapOptions = {
    zoom: locationPermission ? 12 : 9,
    center: defaultCenter,
    zoomControl: true,
    cameraControl: true,
    mapTypeControl: true,
    scaleControl: true,
    streetViewControl: true,
    rotateControl: true,
    fullscreenControl: false,
  };

  const formField = [
    {
      name: "presence_of_children_code",
      label: "Presence Of Children Code",
      value: formData?.presence_of_children_code,
      type: dataProperty?.find(
        (data) => data?.name === "presence_of_children_code"
      )?.fieldType,
      options: dataProperty?.find(
        (data) => data?.name === "presence_of_children_code"
      )?.options,
    },
    {
      name: "residence_household_gender_composition",
      label: "Residence Household Gender Composition",
      value: formData?.residence_household_gender_composition,
      type: dataProperty?.find(
        (data) => data?.name === "residence_household_gender_composition"
      )?.fieldType,
      options: dataProperty?.find(
        (data) => data?.name === "residence_household_gender_composition"
      )?.options,
    },
    {
      name: "household_disposition",
      label: "Household Disposition",
      value: formData?.household_disposition,
      type: "select",
      options: HOUSE_DISPOSITION_OPTIONS,
    },
  ];

  const handleCloseModal = () => {
    setShowAddHouseHold(false);
    setFormData(null);
    setL2Id(null);
    setResponseData(null);
    setHouseHoldUserId(null);
  };
  const handleHouseHoldSubmit = async (e) => {
    const address = selected?.address_components;
    const getAddressComponent = (type) => {
      const component = address?.find((comp) => comp.types.includes(type));
      return component ? component.long_name : '';
    };

    const lat = selected?.geometry?.location?.lat()
    const lng = selected?.geometry?.location?.lng()
    const streetNumber = getAddressComponent('street_number');
    const route = getAddressComponent('route');
    const neighborhood = getAddressComponent('sublocality_level_1');
    const locality = getAddressComponent('locality');
    const administrativeAreaLevel1 = getAddressComponent('administrative_area_level_1');
    const postalCode = getAddressComponent('postal_code');

    setAddHHResponse({
      mailing_city: locality,
      residence_state: administrativeAreaLevel1,
      residence_zip: postalCode
    })
    e.preventDefault();
    let payload = {
      canvasser_email: userMail,
      canvasser_owner: ownerID,
      longitude_numeric: lng.toFixed(6),
      latitude_numeric: lat.toFixed(6),
      coordinate_latitude: lat.toFixed(6),
      coordinate_longitude: lng.toFixed(6),
      mailing_address_line: streetNumber + ' ' + route + ' ' + neighborhood,
      mailing_adress_zip: postalCode,
      mailing_city: locality,
      mailing_address_state: administrativeAreaLevel1,
      presence_of_children_code: formData?.presence_of_children_code,
      residence_household_gender_composition: formData?.residence_household_gender_composition,
      residence_address_line: streetNumber + ' ' + route + ' ' + neighborhood,
      residence_city: locality,
      residence_state: administrativeAreaLevel1,
      residence_zip: postalCode,
    };
    if (houseHoldUserId) {
      try {
        const res = await axios.put(
          `${REACT_APP_BASE_URL}/householdList`,
          {
            id: houseHoldUserId,
            ...payload,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        toast.success("Household Update Successfully", res.data.message);
      } catch (error) {
        toast.error(error?.message);
      }
    } else {
      try {
        const res = await axios.post(
          `${REACT_APP_BASE_URL}/add_household`,
          {
            properties: payload,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (res.status === 200) {
          setResponseData(res?.data?.properties);
          setHouseHoldUserId(res?.data?.properties?.hs_object_id);
          setL2Id(res?.data?.properties?.l2_household_id);
          toast.success("Household Added Successfully");
        }
      } catch (error) {
        toast.error(error?.message);
      }
    }
  };

  return (
    <div className="bg-troo-userbar w-full py-8 flex flex-col gap-3">
      <div className="bg-troo-white md:p-8 p-3 flex flex-col md:gap-3 gap-8 mx-5 rounded">
        <div className="flex items-center justify-between gap-5">
          <h1>Tracking</h1>
          <div className="flex gap-5 items-center">
            <button
              className="bg-troo-secondery bg-opacity-30 md:w-12 w-8 md:h-12 h-8 rounded-full flex justify-center items-center"
              onClick={() => setShowAddHouseHold(true)}
            >
              <BsHouseAdd className="md:text-2xl text-lg" />
            </button>

            <button
              className="bg-troo-secondery bg-opacity-30 md:w-12 w-8 md:h-12 h-8 rounded-full flex justify-center items-center"
              type="button"
              data-hs-overlay="#hs-basic-modal5"
            >
              <FiFilter className="md:text-2xl text-lg" />
            </button>
            <button
              className="p-2 bg-troo-secondery bg-opacity-30 rounded"
              onClick={handleReset}
            >
              Reset
            </button>
          </div>
        </div>
        <div className="flex items-center justify-between md:justify-normal gap-6 lg:gap-16 flex-wrap">
          <div className="flex flex-col gap-1">
            <h3 className="text-troo-secondery font-semibold">Attempts</h3>
            <h3 className="font-normal">{totalAttempt || 0}</h3>
          </div>
          <div className="flex flex-col gap-1">
            <h3 className="text-troo-secondery font-semibold">
              Contacted Rate
            </h3>
            <h3 className="font-normal">{contactedVoters || 0} %</h3>
          </div>
          <div className="flex flex-col gap-1">
            <h3 className="text-troo-secondery font-semibold">Locations</h3>
            <h3 className="font-normal">
              {dataList?.filter((data) => data.is_check)?.length}
            </h3>
          </div>
          <div className="flex flex-col gap-1">
            <h3 className="text-troo-secondery font-semibold">
              Conversion Rate
            </h3>
            <h3 className="font-normal">{totalConversion || 0} %</h3>
          </div>
          <div className="flex flex-col gap-1">
            <h3 className="text-troo-secondery font-semibold">Rating</h3>
            <h3 className="font-normal flex gap-1 items-center">
              {totalRating || 0} <IoMdStar />
            </h3>
          </div>
        </div>
      </div>
      <div className="px-5">
        <ResizablePanel
          leftSide={<div className="p-1 m-1">
            {
              !showAddHouseHold && <TrackingMap
                mapOptions={mapOptions}
                handleOnLoad={handleOnLoad}
                defaultCenter={defaultCenter}
                dataList={mapData.current}
                latLngMap={latLngMap}
                handleEditClick={(data) => handleEditClick(data, true)}
              />
            }
          </div>}
          rightSide={
            <div className="p-1 m-2">
              <div className="w-full bg-troo-white overflow-y-auto h-full max-h-[700px] ">
                <div className="overflow-x-auto custom-scroll-box">
                  <div className="p-1.5 min-w-full inline-block align-middle">
                    <div className="overflow-hidden">
                      <table className="min-w-full divide-y divide-troo-primary">
                        <thead className="border-b-2">
                          <tr>
                            <th
                              scope="col"
                              className="px-2 md:py-3 py-5 text-start text-xs text-troo-secondery font-outfit font-bold flex gap-2 "
                            >
                              <input
                                type="checkbox"
                                className="tableCheckbox"
                                checked={isCheckedAll}
                                onClick={handleCheckedAll}
                              />
                            </th>
                            <th
                              scope="col"
                              className="md:px-6 px-3 py-3 text-center text-xs text-troo-secondery font-outfit font-bold uppercase"
                            >
                              <div className="flex gap-1 justify-center items-center">
                                <p> Address </p>
                                <button onClick={toggleAddressSortingOrder}>
                                  {isAddressAscending ? (
                                    <button onClick={sortDatabaseAddressDescending}>
                                      <FaChevronUp className="h-4 w-4" />
                                    </button>
                                  ) : (
                                    <button onClick={sortDatabaseAddressAscending}>
                                      <FaChevronDown className="h-4 w-4" />
                                    </button>
                                  )}
                                </button>
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="md:px-6 px-3 py-3 text-center text-xs text-troo-secondery font-outfit font-bold uppercase"
                            >
                              View
                            </th>
                            <th
                              scope="col"
                              className="md:px-6 px-3 py-3 text-center text-xs text-troo-secondery font-outfit font-bold uppercase"
                            >
                              Associated Contacts
                            </th>
                            <th
                              scope="col"
                              className="md:px-6 px-3 py-3 text-center text-xs text-troo-secondery font-outfit font-bold uppercase"
                            >
                              Household Disposition
                            </th>
                            <th
                              scope="col"
                              className="md:px-6 px-3 py-3 text-center text-xs text-troo-secondery font-outfit font-bold uppercase"
                            >
                              <div className="flex gap-1 items-center">
                                <p> City </p>
                                <button onClick={toggleCitySortingOrder}>
                                  {isCityAscending ? (
                                    <button onClick={sortDatabaseCityDescending}>
                                      <FaChevronUp className="h-4 w-4" />
                                    </button>
                                  ) : (
                                    <button onClick={sortDatabaseCityAscending}>
                                      <FaChevronDown className="h-4 w-4" />
                                    </button>
                                  )}
                                </button>
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="md:px-6 px-3 py-3 text-center text-xs text-troo-secondery font-outfit font-bold uppercase"
                            >
                              Map
                            </th>

                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 dark:divide-troo-primary/20">
                          {loading ? (
                            <div className="text-center flex justify-center items-center h-[50px]">
                              <SyncLoader color="#43524f" size={5} />
                            </div>
                          ) : (
                            <>
                              {dataList?.map((ele, i) => (
                                <tr
                                  className="hover:bg-troo-primary/15 group/row text-troo-primary transition-all"
                                  key={ele.id}
                                >
                                  <td className="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200 text-center">
                                    <input
                                      type="checkbox"
                                      checked={ele?.is_check}
                                      className="tableCheckbox"
                                      onClick={() => handleCheckedItem(i, ele)}
                                    />
                                  </td>
                                  <td className="md:px-6 px-3 py-2 whitespace-nowrap text-xs font-outfit font-normal text-center transition-all">
                                    {ele?.properties?.residence_address_line ||
                                      "--"}
                                  </td>
                                  <td className="md:px-6 px-3 py-2 whitespace-nowrap text-xs font-outfit font-normal text-center">
                                    <button
                                      type="button"
                                      onClick={() => handleEditClick(ele, false)}
                                      data-hs-overlay="#hs-basic-modal3"
                                      className="md:py-3 py-1 md:px-4 px-2 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-troo-primary text-white hover:scale-95 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                    >
                                      View & Edit
                                    </button>
                                  </td>

                                  <td className="md:px-6 px-3 py-2 whitespace-nowrap text-xs font-outfit font-normal text-center">
                                    <div className="hs-dropdown relative inline-flex">
                                      <button
                                        id="hs-dropdown-hover-event"
                                        className="hs-dropdown-toggle"
                                        onClick={() =>
                                          showAssociateContact(ele?.id)
                                        }
                                      >
                                        <IoIosContacts className="w-7 h-7" />
                                      </button>
                                      <AssociatedContact
                                        fetchStats={fatchStats}
                                        dataProperty={dataProperty}
                                        isAssociateListLoading={
                                          isAssociateListLoading
                                        }
                                        noAddContact
                                        setShowAssociateList={setShowAssociateList}
                                        showAssociateContact={showAssociateContact}
                                        associatedContact={
                                          associatedContact[ele.id]
                                        }
                                      />
                                    </div>
                                  </td>

                                  <td className="md:px-6 px-3 py-2 whitespace-nowrap text-sm font-outfit font-normal text-center transition-all">
                                    {ele?.properties?.household_disposition || "--"}
                                  </td>
                                  <td className="md:px-6 px-3 py-2 whitespace-nowrap text-xs font-outfit font-normal text-center transition-all">
                                    {ele?.properties.residence_city}
                                  </td>
                                  <td className="md:px-6 px-3 py-2 whitespace-nowrap transition-all text-xs font-outfit underline text-center">
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={`https://maps.google.com/?q=${ele?.properties?.residence_address_line}, ${ele?.properties?.mailing_city}, CT, USA `}
                                    >
                                      {ele?.properties?.residence_address_line}{" "}
                                      {ele?.properties.city}{" "}
                                      {ele?.properties?.mailing_city}
                                    </a>
                                  </td>
                                </tr>
                              ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full flex items-center justify-center p-5">
                {!allDataLoaded && !hideLoading && dataList.length > 10 && (
                  <button
                    type="button"
                    className="py-2 px-3 inline-flex items-center justify-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-troo-primary text-troo-white disabled:cursor-progress"
                    onClick={handlePagination}
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Load More"}
                  </button>
                )}
              </div>
            </div>
          }
        />

      </div>
      {/* HouseHold Form */}
      {userId && (
        <Dialog
          open={modalIsOpen}
          onClose={closeModal}
          className="relative z-50"
        >
          <div className="fixed inset-0 flex w-screen items-center justify-center p-4 bg-troo-primary/60 backdrop-blur-sm">
            <div
              className="absolute top-0 left-0 w-full h-screen z-10"
              onClick={closeModal}
            />
            <div className="xl:max-w-6xl lg:max-w-5xl md:max-w-4xl max-w-4xl sm:w-full sm:mx-auto bg bg-white border rounded-xl pointer-events-auto max-h-[calc(100vh-3.5rem)] overflow-y-auto z-[99]">
              <div className="p-4 sm:p-6 md:p-8">
                <h2 className="font-bold uppercase flex justify-center mb-4">
                  Household Details
                </h2>

                {/* {isOpenFromMap && ( */}
                <div className="mt-8  mb-10 flex justify-end">
                  <div className="hs-dropdown relative inline-flex ">
                    <button
                      id="hs-dropdown-hover-event"
                      className="hs-dropdown-toggle font-semibold flex items-center gap-5"
                      onClick={() => showAssociateContact(userId)}
                    >
                      ASSOCIATED CONTACTS <IoIosContacts className="w-7 h-7" />
                    </button>
                    <AssociatedContact
                      isModel
                      fetchStats={fatchStats}
                      dataProperty={dataProperty}
                      isAssociateListLoading={isAssociateListLoading}
                      setShowAssociateList={setShowAssociateList}
                      showAssociateContact={showAssociateContact}
                      associatedContact={associatedContact[userId]}
                      addContactModalData={{
                        l2Id,
                        mailing_city,
                        residence_state,
                        residence_zip,
                      }}
                    />
                  </div>
                </div>
                {/* )} */}
                <form onSubmit={handleSubmit} noValidate>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                    <EditInputField
                      label="Coordinate Latitude"
                      name="coordinate_latitude"
                      value={coordinate_latitude}
                      onChange={(e) => setCoordinate_latitude(e.target.value)}
                    />
                    <EditInputField
                      label="Coordinate Longitude"
                      name="coordinate_longitude"
                      value={coordinate_longitude}
                      onChange={(e) => setCoordinate_longitude(e.target.value)}
                    />
                    <EditInputField
                      label="Mailing Address Line"
                      name="mailing_address_line"
                      value={mailing_address_line}
                      onChange={(e) => setMailing_address_line(e.target.value)}
                    />
                    <EditInputField
                      label=" Mailing Adress Zip"
                      name="mailing_adress_zip"
                      value={mailing_adress_zip}
                      onChange={(e) => setMailing_adress_zip(e.target.value)}
                    />
                    <EditInputField
                      label=" Mailing City"
                      name="mailing_city"
                      value={mailing_city}
                      onChange={(e) => setMailing_city(e.target.value)}
                    />
                    <EditInputField
                      label="Presence Of Children Code"
                      name="presence_of_children_code"
                      value={presence_of_children_code}
                      onChange={(e) =>
                        setPresence_of_children_code(e.target.value)
                      }
                      type={
                        dataProperty?.find(
                          (data) => data?.name === "presence_of_children_code"
                        )?.fieldType
                      }
                      options={
                        dataProperty?.find(
                          (data) => data?.name === "presence_of_children_code"
                        )?.options
                      }
                    />

                    <EditInputField
                      label="Residence Household Gender Composition"
                      name="residence_household_gender_composition"
                      value={residence_household_gender_composition}
                      onChange={(e) =>
                        setResidence_household_gender_composition(
                          e.target.value
                        )
                      }
                      type={
                        dataProperty?.find(
                          (data) =>
                            data?.name ===
                            "residence_household_gender_composition"
                        )?.fieldType
                      }
                      options={
                        dataProperty?.find(
                          (data) =>
                            data?.name ===
                            "residence_household_gender_composition"
                        )?.options
                      }
                    />
                    <EditInputField
                      label="Residence State"
                      name="residence_state"
                      value={residence_state}
                      onChange={(e) => setResidence_state(e.target.value)}
                    />
                    <EditInputField
                      label="  Residence Zip"
                      name="residence_zip"
                      value={residence_zip}
                      onChange={(e) => setResidence_zip(e.target.value)}
                    />
                    <EditInputField
                      label="Household Disposition"
                      name="houseHoldDisposition"
                      value={houseHoldDisposition}
                      onChange={(e) => setHouseHoldDisposition(e.target.value)}
                      type="select"
                      options={HOUSE_DISPOSITION_OPTIONS}
                    />
                  </div>
                  <div className="flex items-center gap-5 mt-5">
                    <button
                      type="submit"
                      disabled={isSubmitLoading}
                      className="shadow bg-troo-primary text-white font-bold py-2 px-6 rounded flex items-center gap-3"
                    >
                      {isSubmitLoading && <ButtonLoader />}
                      Save
                    </button>
                    <button
                      className="bg-gray-200 py-2 px-6 rounded"
                      onClick={(e) => closeModal(e)}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Dialog>
      )}
      {/* HouseHold Search */}
      <div
        id="hs-basic-modal5"
        className="hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 hidden size-full fixed top-0 start-0 z-[80] opacity-0 overflow-x-hidden transition-all overflow-y-auto pointer-events-none"
      >
        <div className="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all sm:max-w-xl sm:w-full m-3 sm:mx-auto">
          <div className="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto">
            <div className="p-4 overflow-y-auto">
              <h2 className="flex justify-center mb-8">Search Field</h2>
              <form className="w-full " onSubmit={(e) => applyFilters(e)}>
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-1/3">
                    <label
                      className="block text-gray-500 font-bold text-center mb-1 md:mb-0 pr-4"
                      htmlFor="input-field"
                    >
                      City
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <AsyncSelect
                      loadOptions={loadCityOptions}
                      id="city"
                      name="city"
                      className="bg-gray-200 appearance-none border-none border-gray-200 rounded w-full  text-gray-700 leading-tight focus:outline-none z-[9999]"
                      classNames={{
                        // menu: () => 'h-40 overflow-y-hidden',
                        // container: () => 'h-40 overflow-y-hidden',
                        menuList: () => "h-40",
                      }}
                      defaultOptions={allCityOptions}
                      value={allCityOptions.find(
                        (option) => option.value === searchCity
                      )}
                      onChange={(selectedOption) =>
                        setSearchCity(
                          selectedOption ? selectedOption.value : null
                        )
                      }
                      isClearable
                    />
                  </div>
                </div>
                {/* <SearchInputField
                  label="City"
                  name="city"
                  onChange={(e) => setSearchCity(e.target.value)}
                /> */}
                <SearchInputField
                  label="Street Name"
                  onChange={(e) => setSearchStreetName(e.target.value)}
                />
                <SearchInputField
                  label="Census Block"
                  name="census_block"
                  onChange={(e) => setSearchCensus_block(e.target.value)}
                />
                <SearchInputField
                  label={
                    <>
                      Presence of <br className="md:block hidden" />
                      Children Code
                    </>
                  }
                  name="presence_of_children_code"
                  onChange={(e) =>
                    setSearchpresence_of_children_code(e.target.value)
                  }
                  type={
                    dataProperty?.find(
                      (data) => data?.name === "presence_of_children_code"
                    )?.fieldType
                  }
                  options={
                    dataProperty?.find(
                      (data) => data?.name === "presence_of_children_code"
                    )?.options
                  }
                />
                <SearchInputField
                  label="Zip Code"
                  onChange={(e) => setSelectedZipCode(e.target.value)}
                />
                <SearchInputField
                  label={<>List Membership</>}
                  onChange={(e) => setSelectedMemberList(e.target.value)}
                  type={"select"}
                  options={listMemberData}
                />

                <div className="flex justify-end items-center gap-x-2">
                  <button
                    type="submit"
                    className="py-3 px-6 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-troo-primary text-troo-white "
                    data-hs-overlay="#hs-basic-modal5"
                  >
                    Search
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={showAddHouseHold}
        onClose={handleCloseModal}
        className="relative z-50"
      >
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4 bg-troo-primary/60 backdrop-blur-sm">
          <div
            className="absolute top-0 left-0 w-full h-screen z-10"
            onClick={handleCloseModal}
          />
          <div className="xl:max-w-6xl lg:max-w-5xl md:max-w-4xl max-w-4xl sm:w-full sm:mx-auto bg bg-white border rounded-xl pointer-events-auto max-h-[calc(100vh-3.5rem)] overflow-y-auto z-[99]">
            <div className="p-4 sm:p-6 md:p-8">
              <h2 className="font-bold uppercase flex justify-center mb-4">
                Add HouseHold Details
              </h2>

              {/* {isOpenFromMap && ( */}
              <div className="mt-8  mb-10 flex justify-end">
                <div
                  className={`hs-dropdown relative inline-flex z-50 ${showContact ? "open" : ""
                    } `}
                >
                  {houseHoldUserId !== "" && (
                    <button
                      id="hs-dropdown-hover-event"
                      className="hs-dropdown-toggle font-semibold flex items-center gap-5"
                      onClick={() => showAssociateContact(houseHoldUserId)}
                    // onClick={() => handleShowContact()}
                    >
                      ASSOCIATED CONTACTS <IoIosContacts className="w-7 h-7" />
                    </button>
                  )}

                  <AssociatedContact
                    isModel
                    fetchStats={fatchStats}
                    dataProperty={dataProperty}
                    isAssociateListLoading={isAssociateListLoading}
                    setShowAssociateList={setShowAssociateList}
                    showAssociateContact={showAssociateContact}
                    associatedContact={associatedContact[houseHoldUserId]}
                    addContactModalData={{
                      l2Id,
                      mailing_city: addHHResponse?.mailing_city,
                      residence_state: addHHResponse?.residence_state,
                      residence_zip: addHHResponse?.residence_zip,
                    }}
                  />
                </div>
              </div>
              {/* )} */}
              <GoogleMapAutocomplete setSelected={setSelected} selected={selected} />
              <form onSubmit={(e) => handleHouseHoldSubmit(e)}>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">

                  {formField?.map((item, index) => {
                    return (
                      <EditInputField
                        key={index}
                        label={item?.label}
                        name={item?.name}
                        value={item?.value}
                        onChange={(e) =>
                          handleInputChange(item?.name, e.target.value)
                        }
                        type={item?.type}
                        options={item?.options}
                        required={item?.required}
                        loading={item?.loading}
                        disabled={item?.loading}
                      />
                    );
                  })}
                </div>
                <div className="flex items-center gap-5 mt-5">
                  <button
                    type="submit"
                    disabled={isSubmitLoading}
                    className="shadow bg-troo-primary text-white font-bold py-2 px-6 rounded flex items-center gap-3"
                  >
                    {isSubmitLoading && <ButtonLoader />}
                    Save
                  </button>
                  <button
                    className="bg-gray-200 py-2 px-6 rounded"
                    onClick={(e) => handleCloseModal(e)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default CanvassMap;
