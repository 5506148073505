import React, { useEffect, useRef, useState } from "react";

const GoogleMapAutocomplete = ({ selected, setSelected }) => {
    const inputRef = useRef(null);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        // Dynamically load Google Maps API script
        const loadGoogleMapsScript = () => {
            const script = document.createElement("script");
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBwBtxw1WbNeANR4SlUgLHN3nuhF1qPaWs&libraries=places`;
            script.async = true;
            script.onload = () => setIsLoaded(true);
            script.onerror = () => alert("Error loading Google Maps API");
            document.head.appendChild(script);
        };

        // Call the function to load the script
        loadGoogleMapsScript();
    }, []);

    useEffect(() => {
        // Initialize the autocomplete only after the script is loaded
        if (isLoaded) {
            initializeAutocomplete();
        }
    }, [isLoaded]);

    const initializeAutocomplete = () => {
        const input = inputRef.current;

        // Initialize Google Maps Autocomplete once the API is loaded
        if (window.google && window.google.maps && window.google.maps.places) {
            const autocompleteOptions = {
                fields: ["formatted_address", "geometry", "address_components"],
            };

            const autocompleteInstance = new window.google.maps.places.Autocomplete(input, autocompleteOptions);

            autocompleteInstance.addListener("place_changed", () => {
                const place = autocompleteInstance.getPlace();
                if (!place?.geometry || !place?.geometry?.location) {
                    alert("No details available for input: '" + place?.name + "'");
                    return;
                }
                setSelected(place);
            });
        }
    };

    if (!isLoaded) {
        return <div>Loading Google Maps...</div>
    }

    return (
        <div className="pb-8">
            <label className="block text-gray-500 font-bold mb-2">Search Address</label>
            <input
                ref={inputRef}
                type="text"
                placeholder="Search for a address near you"
                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white disabled:cursor-not-allowed"
            />
        </div>
    );
};

export default GoogleMapAutocomplete;
