import React, { useEffect, useState } from "react";
import filterIcon from "../images/filterIcon.png";
import ContactTableDatabase from "./ContactTableDatabase";
import axios from "axios";
import SearchInputField from "./SearchInputField";
import {
  createDatabase,
  storeDataInIndexedDB,
  fetchDataFromIndexedDB,
} from "../Hooks/IndexedDBUtils";
import Select from "react-select";
const { REACT_APP_BASE_URL } = process.env;

export const syncableWolkingListDB = createDatabase(
  "syncableWolkingListDB",
  "syncableWalkingListStore"
);

const WalkingList = () => {
  const [activeTab, setActiveTab] = useState("contacts");
  const [userData, setUserData] = useState([]);
  const [hideLoading, setHideLoading] = useState(false);
  const [searchFirstName, setSearchFirstName] = useState("");
  const [searchLastName, setSearchLastName] = useState("");
  const [searchAddress, setSearchAddress] = useState("");
  const [searchCity, setSearchCity] = useState("");
  const [searchZip, setSearchZip] = useState("");
  const [searchAge, setSearchAge] = useState("");
  const [searchState_income_decile, setState_income_decile] = useState("");
  const [searchNational_voter_cluster, setSearchNational_voter_cluster] =
    useState("");
  const [searchCensus_block, setSearchCensus_block] = useState("");
  const [searchPresence_of_children_code, setSearchpresence_of_children_code] =
    useState();
  const [filteredData, setFilteredData] = useState(userData);
  const token = localStorage.getItem("token");
  const userMail = localStorage.getItem("urserMail");
  const ownerID = localStorage.getItem("ownerId");
  const [page, setPage] = useState(1);
  const limit = 100;
  const [loading, setLoading] = useState(false);
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const db3 = createDatabase("walkingListDB", "walkingListStore");
  const [allCityOptions, setAllCityOptions] = useState([]);
  const [allCount, setAllCount] = useState("");
  const [listMemberData, setListMemberData] = useState([]);
  const [selectedMemberList, setSelectedMemberList] = useState("");
  const [offset, setOffset] = useState(0);
  const [isFiltered, setIsFiltered] = useState(false);
  const walking_sort = JSON.parse(localStorage.getItem('sortWalking')) || {}

  const getListMemberShipList = async () => {
    try {
      const res = await axios.get(
        `${REACT_APP_BASE_URL}/list_membership_contact`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setListMemberData(res?.data?.options);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (listMemberData?.length === 0) {
      getListMemberShipList();
    }
  }, []);
  const getCityOptions = async () => {
    try {
      const response = await axios.get(`${REACT_APP_BASE_URL}/getCity`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data) {
        const options = response.data.cities.map((city) => ({
          value: city.name,
          label: city.name,
        }));
        setAllCityOptions(options);
      }
    } catch (error) {
      console.error("Error fetching city data:", error);
    }
  };

  useEffect(() => {
    if (navigator.onLine) {
      fetchDataBase();
      getCityOptions();
    }
  }, []);

  useEffect(() => {
    setFilteredData(userData);
  }, [userData]);

  const fetchDataBase = async (customPage = null, key) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_URL}/database`,
        Object.keys(walking_sort)?.length > 0 ?
          {
            offset: key === 'RESET' ? 0 : offset,
            limit: limit,
            sort_by: walking_sort?.sortBy,
            direction: walking_sort?.direction?.toUpperCase(),
            owner_id: ownerID,
          } :
          {
            offset: key === 'RESET' ? 0 : offset,
            limit: limit,
            owner_id: ownerID,
          },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAllCount(response?.data?.total);
      setOffset((prev) => prev + 100)
      const newData = response.data.results;
      setAllDataLoaded(newData.length === 0);
      setUserData((prevData) =>
        customPage ? newData : [...prevData, ...newData]
      );
      key === "RESET" && localStorage.removeItem("waitingListFilters");
      setPage(customPage ? customPage + 1 : page + 1);
      if (newData.length > 0) {
        storeDataInIndexedDB(db3, "walkingListStore", newData);
      }
      if (!response?.data?.paging) {
        setHideLoading(true)
        setAllDataLoaded(true)
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
      setHideLoading(false);
    }
  };

  useEffect(() => {
    fetchDataFromIndexedDB(db3, "walkingListStore").then((data) => {
      if (navigator.onLine === false) {
        setUserData(data);
      }
    });
  }, []);

  const handlePagination = () => {
    if (!loading && !allDataLoaded) {
      if (isFiltered) {
        filterOnline('', '', offset);
      } else {
        fetchDataBase();
      }
    }
  };
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const filterOnline = async (customPage = null, key = null) => {
    setHideLoading(true);
    setLoading(true);
    key === 'RESET' && setOffset(0)
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_URL}/database`,
        {
          owner_id: ownerID,
          firstname: searchFirstName,
          lastname: searchLastName,
          residence_address_line_1: searchAddress,
          residence_city: searchCity,
          residence_zip: searchZip,
          age: searchAge,
          limit: limit,
          state_income_decile: searchState_income_decile,
          census_block: searchCensus_block,
          national_voter_cluster: searchNational_voter_cluster,
          presence_of_children_code: searchPresence_of_children_code,
          list_membership: selectedMemberList,
          offset: key === 'RESET' ? 0 : offset,
          ...(walking_sort ? {
            sort_by: walking_sort?.sortBy,
            direction: walking_sort?.direction?.toUpperCase(),
          } : "")
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAllCount(response?.data?.total)
      const filterPayload = {
        owner_id: ownerID,
        firstname: searchFirstName,
        lastname: searchLastName,
        residence_address_line_1: searchAddress,
        residence_city: searchCity,
        residence_zip: searchZip,
        age: searchAge,
        state_income_decile: searchState_income_decile,
        census_block: searchCensus_block,
        national_voter_cluster: searchNational_voter_cluster,
        presence_of_children_code: searchPresence_of_children_code,
      };
      if (response.status) {
        setFilteredData((prev) =>
          key === 'RESET' ? [...response?.data?.results] : [...prev, ...response?.data?.results],
        )
        setUserData((prevData) =>
          key === 'RESET' ? [...response?.data?.results] : [...prevData, ...response?.data?.results]
        );
        setOffset((prev) => prev + 100);
      }
      localStorage.setItem("waitingListFilters", JSON.stringify(filterPayload));

      if (!response?.data?.paging) {
        setHideLoading(true)
        setAllDataLoaded(true)
      } else {
        setHideLoading(false)
        setAllDataLoaded(false)
      }
    } catch (error) {
      console.error("API error:", error);
    } finally {
      setLoading(false);
    }
  };
  const filterOffline = (e) => {
    let filtered = userData.slice();
    if (searchFirstName) {
      filtered = filtered.filter(
        (user) =>
          user.properties.firstname &&
          user.properties.firstname
            .toLowerCase()
            .includes(searchFirstName.toLowerCase())
      );
    }
    if (searchLastName) {
      filtered = filtered.filter(
        (user) =>
          user.properties.lastname &&
          user.properties.lastname
            .toLowerCase()
            .includes(searchLastName.toLowerCase())
      );
    }
    if (searchAddress) {
      filtered = filtered.filter(
        (user) =>
          user.properties.residence_address_line_1 &&
          user.properties.residence_address_line_1
            .toLowerCase()
            .includes(searchAddress.toLowerCase())
      );
    }
    if (searchCity) {
      filtered = filtered.filter(
        (user) =>
          user.properties.residence_city &&
          user.properties.residence_city
            .toLowerCase()
            .includes(searchCity.toLowerCase())
      );
    }
    if (searchZip) {
      filtered = filtered.filter(
        (user) =>
          user.properties.residence_zip &&
          user.properties.residence_zip.includes(searchZip)
      );
    }
    if (searchAge) {
      filtered = filtered.filter(
        (user) =>
          user.properties.age && user.properties.age === parseInt(searchAge)
      );
    }
    if (searchState_income_decile) {
      filtered = filtered.filter(
        (user) =>
          user.properties.state_income_decile &&
          user.properties.state_income_decile ===
          parseInt(searchState_income_decile)
      );
    }
    if (searchCensus_block) {
      filtered = filtered.filter(
        (user) =>
          user.properties.census_block &&
          user.properties.census_block.includes(searchCensus_block)
      );
    }
    if (searchNational_voter_cluster) {
      filtered = filtered.filter(
        (user) =>
          user.properties.national_voter_cluster &&
          user.properties.national_voter_cluster ===
          searchNational_voter_cluster
      );
    }
    if (searchPresence_of_children_code) {
      filtered = filtered.filter(
        (user) =>
          user.properties.presence_of_children_code &&
          user.properties.presence_of_children_code ===
          searchPresence_of_children_code
      );
    }
    if (selectedMemberList) {
      filtered = filtered.filter(
        (user) =>
          user.properties.list_membership &&
          user.properties.list_membership === selectedMemberList
      );
    }
    setFilteredData(filtered);
  };

  const applyFilters = (e) => {
    e.preventDefault();
    setIsFiltered(true);
    setOffset(0)
    if (navigator.onLine) {
      filterOnline(1, "RESET");
    } else {
      filterOffline();
    }
  };

  const sortDatabase = async (sortBy, direction, customPage = null) => {
    setLoading(true);
    let walkDB = {
      sortBy: sortBy,
      direction: direction
    }
    localStorage.setItem('sortWalking', JSON.stringify(walkDB))

    const filterdata = JSON.parse(localStorage.getItem("waitingListFilters"));
    if (navigator.onLine) {
      try {
        const response = await axios.post(
          `${REACT_APP_BASE_URL}/database`,
          filterdata
            ? {
              ...filterdata,
              owner_id: ownerID,
              offset: 0,
              limit: limit,
              sort_by: sortBy,
              direction: direction.toUpperCase(),
            }
            : {
              owner_id: ownerID,
              offset: 0,
              limit: limit,
              sort_by: sortBy,
              direction: direction.toUpperCase(),
            },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status) {
          const newData = response.data.results;
          setAllDataLoaded(newData.length === 0);
          setUserData((prevData) =>
            customPage ? newData : [...prevData, ...newData]
          );
          setPage(customPage ? customPage + 1 : page + 1);
          if (newData.length > 0) {
            storeDataInIndexedDB(db3, "walkingListStore", newData);
          }
          setOffset((prev) => prev + 100)
        }
        if (!response?.data?.paging) {
          setHideLoading(true)
          setAllDataLoaded(true)
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
        setHideLoading(false);
      }
    } else {
      const sortedData = [...userData].sort((a, b) => {
        if (direction === "ascending") {
          return a.properties[sortBy] > b.properties[sortBy] ? 1 : -1;
        } else {
          return a.properties[sortBy] < b.properties[sortBy] ? 1 : -1;
        }
      });
      setUserData(sortedData);
      setLoading(false);
      setHideLoading(false);
    }
  };

  const sortDatabaseFirstNameDescending = async (customPage = null) => {
    await sortDatabase("firstname", "descending", customPage);
  };
  const sortDatabaseFirstNameAscending = async (customPage = null) => {
    await sortDatabase("firstname", "ascending", customPage);
  };
  const sortDatabaseLastNameDescending = async (customPage = null) => {
    await sortDatabase("lastname", "descending", customPage);
  };
  const sortDatabaseLastNameAscending = async (customPage = null) => {
    await sortDatabase("lastname", "ascending", customPage);
  };
  const sortDatabaseAddressDescending = async (customPage = null) => {
    await sortDatabase("residence_address_line_1", "descending", customPage);
  };
  const sortDatabaseAddressAscending = async (customPage = null) => {
    await sortDatabase("residence_address_line_1", "ascending", customPage);
  };
  const sortDatabaseZipDescending = async (customPage = null) => {
    await sortDatabase("residence_zip", "descending", customPage);
  };
  const sortDatabaseZipAscending = async (customPage = null) => {
    await sortDatabase("residence_zip", "ascending", customPage);
  };

  const [dataProperty, setDataproperty] = useState([]);
  const DatabaseProperty = async () => {
    let data = "";
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_URL}/databaseProperties`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data) {
        setDataproperty(response.data);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  useEffect(() => {
    if (navigator.onLine) {
      DatabaseProperty();
    }
  }, []);

  const handleReset = () => {
    setIsFiltered(false);
    setOffset(0);
    localStorage.removeItem('sortWalking')
    if (navigator.onLine === false) {
      fetchDataFromIndexedDB(db3, "walkingListStore").then((data) => {
        setUserData(data);
      });
    } else {
      fetchDataBase(1, "RESET");
    }
  };
  return (
    <div>
      <div className=" flex justify-between flex-wrap items-center bg-troo-white py-3">
        <div className="flex items-center gap-8 pl-5 ">
          <div
            className={` text-troo-directoryText py-2 flex gap-3 items-center cursor-pointer uppercase ${activeTab === "contacts" &&
              "border-b-2 border-troo-primary text-troo-primary"
              }`}
            onClick={() => handleTabClick("contacts")}
          >
            <h6 className="font-semibold">Contacts</h6>(
            {navigator.onLine ? allCount : userData.length})
          </div>
        </div>
        <div></div>
        <div className="flex items-center gap-8 px-5 border-x-2"></div>
        <div className="flex gap-5 items-center pr-5">
          <button type="button" data-hs-overlay="#hs-basic-modal">
            <img className="w-5 h-5" src={filterIcon} alt="filterIcon" />
          </button>
          <button
            className="p-2 bg-troo-secondery bg-opacity-30 rounded"
            // onClick={() => fetchDataBase(1, "RESET")}
            onClick={handleReset}
          >
            Reset
          </button>
        </div>
      </div>
      {activeTab === "contacts" && (
        <ContactTableDatabase
          IDBtype="walking"
          dataProperty={dataProperty}
          sortDatabaseFirstNameDescending={sortDatabaseFirstNameDescending}
          sortDatabaseFirstNameAscending={sortDatabaseFirstNameAscending}
          sortDatabaseLastNameDescending={sortDatabaseLastNameDescending}
          sortDatabaseLastNameAscending={sortDatabaseLastNameAscending}
          sortDatabaseAddressDescending={sortDatabaseAddressDescending}
          sortDatabaseAddressAscending={sortDatabaseAddressAscending}
          sortDatabaseZipDescending={sortDatabaseZipDescending}
          sortDatabaseZipAscending={sortDatabaseZipAscending}
          fetchDataBase={fetchDataBase}
          setFilteredData={setFilteredData}
          filteredData={filteredData}
          userData={userData}
          loading={loading}
          db3={db3}
          syncableWolkingListDB={syncableWolkingListDB}
        />
      )}

      {!allDataLoaded &&
        !hideLoading &&
        filteredData?.length >= 10 &&
        navigator.onLine && (
          <div className="w-full flex items-center justify-center p-5">
            <button
              type="button"
              className="py-2 px-3 inline-flex items-center justify-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-troo-primary text-troo-white disabled:cursor-progress"
              onClick={handlePagination}
              disabled={loading}
            >
              {loading ? "Loading..." : "Load More"}
            </button>
          </div>
        )}
      {/* search Model */}
      <div
        id="hs-basic-modal"
        className="hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 hidden size-full fixed top-0 start-0 z-[80] opacity-0 overflow-x-hidden transition-all overflow-y-auto pointer-events-none"
      >
        <div className="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all sm:max-w-2xl  m-3 sm:mx-auto">
          <div className="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto max-h-[800px]">
            <div className="p-4 overflow-y-auto">
              <h4 className="pb-5 flex justify-center">
                Max. 3 search together allowed.
              </h4>
              <form className="w-full" onSubmit={(e) => applyFilters(e)}>
                <SearchInputField
                  label="First Name"
                  onChange={(e) => setSearchFirstName(e.target.value)}
                />
                <SearchInputField
                  label="Last Name"
                  onChange={(e) => setSearchLastName(e.target.value)}
                />

                <SearchInputField
                  label="Street Address"
                  onChange={(e) => setSearchAddress(e.target.value)}
                />
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-1/3">
                    <label
                      className="block text-gray-500 font-bold text-left mb-1 md:mb-0 pr-4"
                      htmlFor="input-field"
                    >
                      City
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <Select
                      id="city"
                      name="city"
                      className="bg-gray-200 appearance-none border-none border-gray-200 rounded w-full  text-gray-700 leading-tight focus:outline-none "
                      options={allCityOptions}
                      value={allCityOptions.find(
                        (option) => option.value === searchCity
                      )}
                      onChange={(selectedOption) =>
                        setSearchCity(
                          selectedOption ? selectedOption.value : null
                        )
                      }
                      isClearable
                    />
                  </div>
                </div>
                <SearchInputField
                  label="Zip Code"
                  onChange={(e) => setSearchZip(e.target.value)}
                />
                <SearchInputField
                  label="Age"
                  onChange={(e) => setSearchAge(e.target.value)}
                />
                <SearchInputField
                  label="State Income Decile"
                  onChange={(e) => setState_income_decile(e.target.value)}
                  type={
                    dataProperty?.find(
                      (data) => data?.name === "state_income_decile"
                    )?.fieldType
                  }
                  options={
                    dataProperty?.find(
                      (data) => data?.name === "state_income_decile"
                    )?.options
                  }
                />
                <SearchInputField
                  label="National Voter Cluster"
                  onChange={(e) =>
                    setSearchNational_voter_cluster(e.target.value)
                  }
                  type={
                    dataProperty?.find(
                      (data) => data?.name === "national_voter_cluster"
                    )?.fieldType
                  }
                  options={
                    dataProperty?.find(
                      (data) => data?.name === "national_voter_cluster"
                    )?.options
                  }
                />
                <SearchInputField
                  label="Census Block"
                  onChange={(e) => setSearchCensus_block(e.target.value)}
                />
                <SearchInputField
                  label={
                    <>
                      Presence of <br className="md:block hidden" />
                      Children Code
                    </>
                  }
                  onChange={(e) =>
                    setSearchpresence_of_children_code(e.target.value)
                  }
                  type={
                    dataProperty?.find(
                      (data) => data?.name === "presence_of_children_code"
                    )?.fieldType
                  }
                  options={
                    dataProperty?.find(
                      (data) => data?.name === "presence_of_children_code"
                    )?.options
                  }
                />
                <SearchInputField
                  label={<>List Membership</>}
                  onChange={(e) => setSelectedMemberList(e.target.value)}
                  type={"select"}
                  options={listMemberData}
                />

                <div className="flex justify-end items-center gap-x-2">
                  <button
                    type="submit"
                    className="py-3 px-6 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-troo-primary text-troo-white "
                    data-hs-overlay="#hs-basic-modal"
                  >
                    Search
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalkingList;
