import React, { useEffect, useState } from "react";
import axios from "axios";
import SpinnerLoader from "../Components/SpinnerLoader";

const { REACT_APP_BASE_URL } = process.env;

const ScheduleMeet = () => {
  const [currentUser, setCurrentUser] = useState({});
  const token = localStorage.getItem("token");
  const email = localStorage.getItem("urserMail");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)

    const script = document.createElement("script");
    script.src =
      "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
    script.async = true;
    script.onload = getCurrentUser;
    document.body.appendChild(script);
    setLoading(false)
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const getCurrentUser = async () => {
    setLoading(true)
    try {
      const response = await axios.post(
        `${REACT_APP_BASE_URL}/user`,
        {
          email: email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCurrentUser(response?.data);
      setLoading(false)

    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  };

  return (
    <div className="bg-troo-userbar h-screen md:p-10 p-2 overflow-hidden">
      {
        loading ?
          <div className="h-full w-full flex justify-center items-center ">
            <SpinnerLoader className={'max-w-20 max-h-20 text-gray-200 animate-spin fill-troo-primary'} />
          </div> : (currentUser?.meet_embedded ? (
            <iframe
              title="meeting embeded"
              className="meetings-iframe-container w-full h-screen bg-transparent overflow-hidden"
              src={currentUser?.meet_embedded}
            ></iframe>
          ) : (
            <h2 className="flex items-center justify-center font-outfit text-troo-primary">
              Please add user Meeting Code URL in Profile Setting
            </h2>
          ))
      }
    </div>
  );
};

export default ScheduleMeet;
